import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

let httpOptions1 = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Token': localStorage.getItem('key'),
    'System-ID' :localStorage.getItem('#')
  })
};

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Token': localStorage.getItem('key'),
  })
};

let header = new HttpHeaders();
header = header.set('Access-Token', localStorage.getItem('key'));
header = header.set('System-ID', localStorage.getItem('#'));

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public api ="https://api.dev.doohanev.com/api/v1/admin-panel/";

  HttpHeaders =new HttpHeaders({

  })
  bearer: string;

     constructor(private http: HttpClient,private router: Router) {
       
      }

     UserLogin(payload: any): Observable<any> {
        return this.http.post<any>(this.api + 'login/',payload)   
      }

      ForgotPassword(payload: any): Observable<any> {
        return this.http.put<any>(this.api + 'password/reset/',payload)    
      }

      //super admin

      ListDevice(): Observable<any> {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Token': localStorage.getItem('key'),
          })
        };
        return this.http.get<any>(this.api + 'assets/',httpOptions)   
      }

      DeviceDetails(payload): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Access-Token', localStorage.getItem('key'));
        // headers = headers.set('System-ID', localStorage.getItem('#'));
        let params = new HttpParams().set('bike_id', payload);
        const options = { params: params, headers: headers };
        return this.http.get<any>(this.api + 'bikes/details/',options )   
      }

      DeviceDetails1(payload): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Access-Token', localStorage.getItem('key'));
        headers = headers.set('System-ID', localStorage.getItem('#'));
        let params = new HttpParams().set('bike_id', payload);
        const options = { params: params, headers: headers };
        return this.http.get<any>(this.api + 'bikes/details/',options )   
      }


      Users(): Observable<any> {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Token': localStorage.getItem('key'),
          })
        };
        return this.http.get<any>(this.api + 'system/',httpOptions)   
      }

      ActiveDevice(): Observable<any> {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Token': localStorage.getItem('key'),
          })
        };
        return this.http.get<any>(this.api + 'map/sort/?key=active', httpOptions)    
      }

      DeviceList(): Observable<any> {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Token': localStorage.getItem('key'),
            'System-ID' :localStorage.getItem('#')
          })
        };
        return this.http.get<any>(this.api + 'e-scooter/list/',httpOptions)   
      }

      SimList(): Observable<any> {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Token': localStorage.getItem('key'),
            // 'System-ID' :localStorage.getItem('#')
          })
        };
        return this.http.get<any>(this.api + 'sim/list',httpOptions)   
      }

      SimManage(payload): Observable<any> {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Token': localStorage.getItem('key'),
            // 'System-ID' :localStorage.getItem('#')
          })
        };
        return this.http.put<any>(this.api + 'sim/manage',payload,httpOptions)   
      }


      DeviceStatus(payload): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Access-Token', localStorage.getItem('key'));
        headers = headers.set('System-ID', localStorage.getItem('#'));
        let params = new HttpParams().set('imei', payload);
        const options = { params: params, headers: headers };
        return this.http.get<any>(this.api + 'e-scooter/heartbeat/',options)   
      }

      Settings1(payload,asset_id): Observable<any> {
        var asset_id = asset_id
        console.log(asset_id)
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Token': localStorage.getItem('key')
          })
        };
        return this.http.post<any>('https://api.dev.doohanev.com/api/v1/system/scooter/manage/'+asset_id,payload,httpOptions)  
      }

      GetStatus(bike_id): Observable<any> {
        var asset_id = bike_id
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Token': localStorage.getItem('key')
          })
        };
        return this.http.get<any>('https://api.dev.doohanev.com/api/v1/system/scooter/manage/'+asset_id,httpOptions)  
      }

      GetStatus1(bike_id): Observable<any> {
        var asset_id = bike_id
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Token': localStorage.getItem('key'),
            'System-ID' :localStorage.getItem('#'),
            'System-Token' :localStorage.getItem('s')
          })
        };
        return this.http.get<any>('https://api.dev.doohanev.com/api/v1/system/scooter/manage/'+asset_id,httpOptions)  
      }



     

      //system admin 

      
      ListDevices(): Observable<any> {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Token': localStorage.getItem('key'),
            'System-ID' :localStorage.getItem('#')
          })
        };
        return this.http.get<any>(this.api + 'assets/',httpOptions)   
      }

      SimLists(): Observable<any> {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Token': localStorage.getItem('key'),
            'System-ID' :localStorage.getItem('#')
          })
        };
        return this.http.get<any>(this.api + 'sim/list',httpOptions)   
      }

      SimManages(payload): Observable<any> {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Token': localStorage.getItem('key'),
            'System-ID' :localStorage.getItem('#')
          })
        };
        return this.http.put<any>(this.api + 'sim/manage',payload,httpOptions)   
      }

      Settings(payload,bike_id): Observable<any> {
        var asset_id = bike_id
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Token': localStorage.getItem('key'),
            'System-ID' :localStorage.getItem('#'),
            'System-Token' :localStorage.getItem('s')
          })
        };
        return this.http.post<any>('https://api.dev.doohanev.com/api/v1/system/scooter/manage/'+asset_id,payload,httpOptions)  
      }
  
      Logout(){
        localStorage.removeItem('key')
        localStorage.removeItem('#')   
        localStorage.removeItem('r')
        localStorage.removeItem('s')
        localStorage.removeItem('.')
        localStorage.removeItem('name') 
        localStorage.removeItem('description') 
        localStorage.removeItem('phone') 
        localStorage.removeItem('email')     
        localStorage.removeItem('call_back_url') 
        this.router.navigate(['/login']);        
      }
}


